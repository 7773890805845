<template>
  <!-- Start Service Area  -->
  <v-row class="service-one-wrapper">
    <!-- Start Single Service  -->
    <v-col
      lg="6"
      md="6"
      sm="6"
      cols="12"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--4">
        <router-link to="/presignuplanding">
          <div class="service">
            <div class="icon" v-html="iconSvg(service.icon)"></div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
  <!-- Start Service Area  -->
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "cast",
            title: "Central HOA Management",
            desc: ` Manage all your HOAs in one place. Lightning fast reporting on all your communities.`,
          },
          {
            icon: "layers",
            title: "Homeowner Engagement",
            desc: ` Modern branded HOA websites to our engaging community mobile app. It will feel like your own branded community software. `,
          },
          {
            icon: "users",
            title: "Smart Accounting",
            desc: `Spend less time manually entering data for your accounting and let Emerie help superpower your accounting abilities.`,
          },
          {
            icon: "monitor",
            title: "CC&R with integrated A.I",
            desc: `Need to visit 500 properties in one day and tired of manually entering data for every photo you take? Emerie can help you create descriptions of your photos in minutes, speech to text translation and much more.`,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
