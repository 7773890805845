<template>
  <div class="row mt_dec--30">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--2 bg-color-gray">
        <router-link to="/presignuplanding">
          <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.icon)"></div>
            </div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "cast",
            title: "Rental Listing Syndications",
            desc: ` Promoting your listing the easy way. Quickly syndicate to websites like Apartments.com and Zillow.`,
          },
          {
            icon: "layers",
            title: "Property Inspections",
            desc: ` Inspections on the go. Quickly create inspection reports using our mobile app.`,
          },
          {
            icon: "users",
            title: "Leasing made easy",
            desc: `Easily build online rental applications for your properties.`,
          },
          {
            icon: "monitor",
            title: "Property Portofolio",
            desc: `Manage all your properties in a single place. Blazing fast and flexible reporting`,
          },
          {
            icon: "users",
            title: "e-Signature",
            desc: `Creating an easy signing experience. Gather electronic signatures securely and allow applicants to sign from anywhere.`,
          },
          {
            icon: "monitor",
            title: "Open API",
            desc: ` Yes we would like you to intergate with our platform. Our customer API is growing daily.`,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
