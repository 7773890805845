<template>
  <div class="active-dark">
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../assets/images/logo/p361_logo1.png" style="width:350px"/>
    </Header>
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <div class="slider-wrapper">
      <SliderOne></SliderOne>
    </div>
    <!-- End Slider Area -->

    <!-- Start About Area -->
    <div class="about-area about-position-top bg_color--1 pb--120">
      <About>
        <img
          slot="thum-img"
          class="w-100"
          src="../assets/images/about/ai6.jpg"
          alt="About Images"
        />
      </About>
    </div>
    <!-- End About Area -->

    <!-- Start Service Area -->
    <div class="service-area ptb--80  bg_image bg_image--3">
      <v-container>
        <v-row>
          <v-col lg="4" md="4" sm="12" cols="12">
            <div
              class="section-title text-left mt--30 mt_md--5 mt_mobile--5 mb_mobile--10"
            >
              <h2 class="heading-title">Association Focus</h2>
              <p>
                Manage all your HOAs with our cloud based application and our smart mobile apps. 
              </p>
              <p>
                We have something for every role, CEO, Finance & Accounting and Property Managers.
              </p>
              <div class="service-btn">
                <a class="btn-transparent rn-btn-dark" href="/presignuplanding"
                  ><span class="text">Pre-Register for the HOA beta</span></a
                >
              </div>
            </div>
          </v-col>
          <v-col lg="8" md="8" sm="12" cols="12" class="mt_md--50">
            <ServiceTwo />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Service Area -->

        <!-- Start Service Area -->
    <!-- <div class="service-area ptb--80  bg_image bg_image--3">
      <v-container>
        <v-row>
          <v-col lg="4" md="4" sm="12" cols="12">
            <div
              class="section-title text-left mt--30 mt_md--5 mt_mobile--5 mb_mobile--10"
            >
              <h2 class="heading-title">Property Management</h2>
              <p>
                Save your property managers significant time and increase your profits by having time to focus on what matters most. We get it. You spend hours a day replying to emails, maintenance requests, lease applications and more. Let Emerie help you automate 60% of your daily tasks.
              </p>
              <div class="service-btn">
                <a class="btn-transparent rn-btn-dark" href="/presignuplanding?promo=Property Management"
                  ><span class="text">Pre-Register for Property Beta</span></a
                >
              </div>
            </div>
          </v-col>
          <v-col lg="8" md="8" sm="12" cols="12" class="mt_md--50">
            <ServiceOne />
          </v-col>
        </v-row>
      </v-container>
    </div> -->
    <!-- End Service Area -->

    <!-- Start Portfolio Area -->
    <div class="rn-portfolio-area rn-section-gap bg_color--1">
      <div class="portfolio-sacousel-inner pb--30">
        <div class="container">
          <div class="row">
            <v-col lg="12">
              <div class="section-title text-center mb--20 mb_sm--0 mb_md--0">
                <h2 class="heading-title">Beta Program</h2>
                <p>
                  We are looking for more beta clients all the time.
                  <br />
                  Let's get Emerie working for you.
                </p>
                <div class="about-button mt--50">
                  <a class="rn-button-style--2 btn_solid" href="/presignuplanding"
                    >I'm Interested</a
                  >
                </div>
              </div>
            </v-col>
          </div>
        </div>
      </div>
    </div>
    <!-- End Portfolio Area -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
  import Header from "../components/header/Header";
  import SliderOne from "../components/slider/SliderOne";
  import About from "../components/about/AboutMain";
  import ServiceOne from "../components/service/ServiceThree";
  import ServiceTwo from "../components/service/ServiceTwo";
  import Portfolio from "../components/portfolio/Portfolio";
  import CounterOne from "../components/counter/CounterOne";
  import Testimonial from "../components/testimonial/Testimonial";
  import Blog from "../components/blog/Blog";
  import Brand from "../components/brand/Brand";
  import Footer from "../components/footer/Footer";

  export default {
    components: {
      SliderOne,
      Header,
      About,
      ServiceTwo,
      Portfolio,
      CounterOne,
      Testimonial,
      Blog,
      Brand,
      Footer,
      ServiceOne
    },
    data() {
      return {};
    },
  };
</script>
