<template>
  <v-row>
    <!-- Start Single Service  -->
    <v-col
      xl="4"
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(item, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--3 text-white">
        <div class="icon">
          <img :src="item.src" alt="Creative Agency" />
        </div>
        <div class="content">
          <h3 class="heading-title">
            {{ item.title }}
          </h3>
          <p>
            {{ item.desc }}
          </p>
        </div>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        serviceContent: [
          {
            src: require("../../assets/images/icons/icon-02.png"),
            title: "A.I. assited Community Management",
            desc: `Meet Emerie1. Our A.I. assisted platform to help manage your communities. Full suite of association management features with lightning fast reporting.`,
          },
          {
            src: require("../../assets/images/icons/icon-01.png"),
            title: "All in one HOA Management",
            desc: `Easy workflows, mobile CC&R management, HOA websites, Accounting, Banking and Flexible Reporting.`,
          },
          {
            src: require("../../assets/images/icons/icon-03.png"),
            title: "Mobile Apps",
            desc: `The most sophisticated CC&R mobile application in the market today as well as a user friendly home owners app branded as your own.`,
          },
        ],
      };
    },
  };
</script>
